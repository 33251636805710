// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-villages-village-markdown-jsx": () => import("/home/travis/build/knowit/stromstad.knowit.no/src/components/Villages/VillageMarkdown.jsx" /* webpackChunkName: "component---src-components-villages-village-markdown-jsx" */),
  "component---src-pages-404-js": () => import("/home/travis/build/knowit/stromstad.knowit.no/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/travis/build/knowit/stromstad.knowit.no/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schedule-js": () => import("/home/travis/build/knowit/stromstad.knowit.no/src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-js": () => import("/home/travis/build/knowit/stromstad.knowit.no/src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/travis/build/knowit/stromstad.knowit.no/.cache/data.json")

